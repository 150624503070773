import React from "react"
import NefiPrices from "../../data/nefi-chat-pricing"

const CheckCircle = ({}) => {
    return (<svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-check-circle mt-1"
            viewBox="0 0 16 16"
            style={{ position: "relative", top: "-1px" }}
        >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
        </svg>
    )
}

const Pricing = () => {
    const handleCheckout = (/** @type {any} */ product) => {
        // make api request
        const url = `/api/buy-subscription`
        const errorUrl = `/ai-chatbot-nefi/error`
        fetch(url, {
            method: "POST",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ product_id: product.product_id, qty: bundle ? qty : 1 }),
        })
            .then(r => {
                if (r.status === 200) {
                    return r.json()
                } 
                window.location.href = errorUrl
            })
            .then(data => {
                window.location.href = data.url
            })
            .catch(e => {
                console.error(e)
                window.location.href = errorUrl
            })
    }
    const [bundle, setBundle] = React.useState(false)
    const [qty, setQty] = React.useState(5)
    const onQtyChange=  React.useCallback(e => {
        if(e.target.value) {
            let val = parseInt(e.target.value) || 1
            if(!val || val < 1) val = 1;
            setQty(val);
        }
    })
    const computeDiscount = (bundle, qty) => {
        if(!bundle) return 1.0;
        if(qty >= 100) return 0.8;
        if( qty >= 50) return 0.9;
        if( qty >= 5) return 0.95;
        return 1.0
    }
    const totalPrice = (price, bundle, qty) => {
        qty = bundle ? qty : 1
        const p = Math.round((computeDiscount(bundle, qty) * price), 0)
        return Math.round(p * qty, 0)
    }
    const formatPrice = (price) => { return new Intl.NumberFormat("en-US").format(price)
    }
    return (

        <div className="max-container py-16 md:py-32 p-5 mx-auto space-y-8">
            <div className="text-center space-y-4">
                <a id="pricing" />
                <h2 className="text-var-6xl text-center font-bold leading-none pb-10">Our Affordable Pricing Plans</h2>
                <p className="text-gray-600 leading-relaxed xl:w-2/4 lg:w-3/4 text-xl mx-auto">
                    We endeavored to create the most versatile pricing plan, but if you
                    have not found a package that meets your needs, please contact us
                    and we will tailor it to your requirements.
                </p>
            </div>

            <div className="mt-16 flex justify-center">
              <fieldset ariaLabel="Payment frequency">
                <div className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200">
                  <label onClick={() => setBundle(false)} className={(!bundle ? "bg-indigo-600 text-white" : "text-gray-500") +  " cursor-pointer rounded-full px-2.5 py-1"}>
                    <span>Single Domain</span>
                  </label>
                  <label onClick={() => setBundle(true)} className={(bundle ? "bg-indigo-600 text-white" : "text-gray-500")+  " cursor-pointer rounded-full px-2.5 py-1"}>
                    <span>Bundle</span>
                  </label>
                </div>
              </fieldset>
            </div>




            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {Object.keys(NefiPrices).map(key => {
                    const price = NefiPrices[key]
                    if(price["archived"]) return null
                    return (
                      <div
                        className="p-6 bg-white shadow-md rounded-lg max-w-md mx-auto w-full"
                        key={key}
                      >
                        <h3 className="text-xl font-semibold mb-4">
                          {price.name}
                        </h3>
                        <div className="flex gap-4">
                          <h4 className="text-4xl">
                            ${ formatPrice(totalPrice(price.base_price, bundle, qty))}
                          </h4>
                          <span className="text-gray-500">
                            per <br /> month
                          </span>
                          {bundle && qty >= 5 && (
                            <span className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600 justify-end h-7">
                              Save{" "}
                              {Math.round(
                                100 * (1.0 - computeDiscount(bundle, qty))
                              )}
                              %
                            </span>
                          )}
                        </div>
                        {bundle ? (
                          <div className="flex items-center justify-center gap-4 mx-auto w-auto">
                            <div className="w-full max-w-max leading-6">
                              Domains:
                            </div>
                            <input
                              type="number"
                              min="1"
                              name="qty"
                              onChange={onQtyChange}
                              value={qty}
                              className="block w-32 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        ) : null}
                        <button
                          onClick={() => handleCheckout(price)}
                          className="mx-auto text-center gradient-bg-purple block
                                hover:gradient-bg-fire text-white hover:text-black uppercase font-bold text-xl rounded-full my-4
                                md:w-auto w-full py-4 px-8 hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 lg:w-1/2"
                        >
                          Subscribe
                        </button>
                        <p className="mt-6 mb-4 font-semibold">
                          This plan includes {bundle ? "(per domain)" : ""}:
                        </p>

                        <ul className="space-y-2">
                          {price.features.map((feature, i) => {
                            return (
                              <li key={i} className="flex items-start gap-x-2 ">
                                <CheckCircle className="mt-2 text-green-500" />
                                {feature}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Pricing
