const NEFI_CHAT_PRICING = {
  "nefi_starter": {
    "name": "Yachting AI Chat - Nefi Starter",
    "product_id": "prod_NpOlJ450TS9DKt",
    "base_price": 97,
    "chats": 50,
    "per_chat": 2,
    "base_price_id": "price_1Q1tvgCGm8qrqF6RLX3VTscp",
    "per_chat_price_id": "price_1NpbblCGm8qrqF6Rpjc2hfNe",
    "features":  [
      "50 monthly chats (included)",
      "$2 per additional chat",
      "1,000 Tokens Knowledge Base",
      "Color Customization",
      "CRM Integration (Webhooks)",
      "Reads Schema.org"
    ]
  },
  "nefi_premium": {
    "name": "Yachting AI Chat - Nefi Premium",
    "product_id": "prod_NpOmfSHyfe0KQ3",
    "base_price": 297,
    "chats": 300,
    "per_chat": 1,
    // "base_price_id": "price_1NUHMaCGm8qrqF6RpfeNJfHb",
    "base_price_id": "price_1Q1ty3CGm8qrqF6R2CcQf8FD",
    "per_chat_price_id": "price_1NpbhCCGm8qrqF6R3PP3uR0F",
    "features":  [
      "300 monthly chats (included)",
      "$1 per additional chat",
      "4,000 Tokens Knowledge Base",
      "Color Customization",
      "Logo Customization",
      "CRM Integration (Webhooks)",
      "Up to 1 connected Database / API Integration",
      "Reads Schema.org"
    ]
  },
  "nefi_enterprise": {
    "name": "Yachting AI Chat - Nefi Enterprise",
    "product_id": "prod_NpOmxPyhjrOOSZ",
    "chats": 1000,
    "base_price": 497,
    "per_chat": 0.6,
    "base_price_id": "price_1Q1tzVCGm8qrqF6RH73ypkMa",
    "per_chat_price_id": "price_1NpbjqCGm8qrqF6RiERpS40o",
    "features":  [
      "1,000 monthly chats (included)",
      "$0.60 per additional chat",
      "10,000 Tokens Knowledge Base",
      "Color Customization",
      "Logo Customization",
      "CRM Integration (Webhooks)",
      "Up to 5 connected Database / API Integration",
      "Reads Schema.org"
    ]
  },
  "nefi_premium_old": {
    "name": "Nefi Premium",
    "product_id": "prod_NpOmfSHyfe0KQ3_A",
    "base_price": 190,
    "chats": 300,
    "per_chat": 0.65,
    "archived": true,
    // "base_price_id": "price_1NUHMaCGm8qrqF6RpfeNJfHb",
    "base_price_id": "price_1NUHMaCGm8qrqF6RpfeNJfHb",
    "per_chat_price_id": "price_1NUHMaCGm8qrqF6RY0zqrGyl",
    "features":  [
      "300 monthly chats (included)",
      "$0.65 per additional chat",
      "4,000 Tokens Knowledge Base",
      "Color Customization",
      "Logo Customization",
      "CRM Integration (Webhooks)",
      "Up to 1 connected Database / API Integration",
      "Reads Schema.org"
    ]
  }
}
export default NEFI_CHAT_PRICING
